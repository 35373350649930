import { EmptyProps } from "@/definitions"
import { Menu, X } from "lucide-react"
import React, { useState } from "react"
import { navItems } from "../../constans/index"
import { ThemeSwitch } from "../common"


const Navbar: React.FC<EmptyProps> = () => {
    const [mobileDrawerOpen, setMobileDrawerOpen] = useState(false);

    const toggleNavBar = () => {
        setMobileDrawerOpen(!mobileDrawerOpen)
    }

    return (
        <nav className="sticky top-0 z-50 py-1 backdrop-blur-lg border-b border-color-navborder -700/80">
            <div className="div container px-4 mx-auto relative text-sm">
                <div className="flex justify-between item-center">
                    <ul className='hidden lg:flex ml-14 space-x-12 my-2'>
                        {navItems.map((item, index) => {
                            return <li key={index}>
                                <a href={item.href} className="transform transition duration-300 whitespace-nowrap text-lg font-medium hover:text-primary">{item.label}</a>
                            </li>
                        })}
                    </ul>
                    <div className="lg:hidden md:flex flex-col justify-end my-2">
                        <button onClick={toggleNavBar}>
                            {mobileDrawerOpen ? <X /> : <Menu />}
                        </button>
                    </div>
                    <div className="lg:flex justify-center space-x-12 items-center mt-1 lg:mt-0">
                        <ThemeSwitch />
                    </div>
                </div>
                {mobileDrawerOpen && (
                    <div className="bg-gray-100/90 dark:bg-gray-900/90 backdrop-blur-md fixed right-0 z-20 w-full p-12 flex flex-col justify-center item-center lg:hidden">
                        <ul>
                            {navItems.map((item, index) => (
                                <li key={index} className="py-4">
                                    <a className="whitespace-nowrap text-lg font-medium hover:text-emerald" href={item.href}>{item.label}</a>
                                </li>
                            ))}
                        </ul>
                    </div>
                )}
            </div>
        </nav>
    )
}

export default Navbar