import React, { SVGProps } from "react"

export const Linkedin: React.FC<SVGProps<SVGElement>> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={className}
    >
      <path
        d="M436.3 436.3h-75.9V317.5c0-28.3-.5-64.8-39.4-64.8-39.5 0-45.5 30.9-45.5 62.8v120.8h-75.9V191.9h72.8v33.4h1c10.1-19.2 34.9-39.5 71.8-39.5 76.9 0 91.1 50.6 91.1 116.4v134.1zM113.9 158.6c-24.4 0-44.1-19.7-44.1-44.1 0-24.3 19.7-44 44.1-44 24.3 0 44 19.7 44 44s-19.7 44.1-44 44.1zm38 277.7h-76V191.9h76v244.4zM490.7 0H21.3C9.6 0 0 9.6 0 21.3v469.3C0 502.4 9.6 512 21.3 512h469.3c11.8 0 21.3-9.6 21.3-21.3V21.3C512 9.6 502.4 0 490.7 0z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#0077b5"
      />
    </svg>
  )
}

export const Twitter: React.FC<SVGProps<SVGElement>> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={className}
    >
      <path
        d="M512 97.2c-18.8 8.4-39.1 14-60.3 16.5 21.7-13 38.3-33.6 46.2-58.1-20.3 12.1-42.8 20.8-66.7 25.5-19.2-20.4-46.5-33.2-76.7-33.2-58 0-105.1 47-105.1 105 0 8.2.9 16.3 2.7 23.9C164.8 172.4 87.4 130.6 35.6 67c-9 15.5-14.2 33.6-14.2 52.8 0 36.4 18.5 68.6 46.7 87.4-17.2-.6-33.4-5.3-47.6-13.1v1.3c0 50.9 36.2 93.4 84.3 103-8.8 2.4-18.1 3.7-27.7 3.7-6.8 0-13.4-.7-19.8-1.9 13.4 41.7 52.2 72.1 98.1 73-36 28.2-81.3 44.9-130.5 44.9-8.5 0-16.9-.5-25.1-1.4 46.5 29.8 101.7 47.2 161 47.2 193.2 0 298.9-160.1 298.9-298.9 0-4.6-.1-9.1-.3-13.6 20.7-14.6 38.5-33.1 52.6-54.2"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#1da1f2"
      />
    </svg>
  )
}

export const Github: React.FC<SVGProps<SVGElement>> = ({ className }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
      className={className}
    >
      <path
        d="M256.8 5.9C114.7 5.9 0 120.6 0 262.7c0 113 73.6 208.9 176.4 243.1 13.7 1.7 17.1-5.1 17.1-12v-44.5c-71.9 15.4-87.3-34.2-87.3-34.2-12-29.1-29.1-37.7-29.1-37.7-24-15.4 1.7-15.4 1.7-15.4 25.7 1.7 39.4 25.7 39.4 25.7 22.3 39.4 59.9 27.4 75.3 20.6 1.7-17.1 8.6-27.4 17.1-34.2-56.5-6.8-116.4-29.1-116.4-126.7 0-27.4 10.3-51.4 25.7-68.5-3.4-6.9-12-32.5 1.7-68.5 0 0 22.3-6.9 70.2 25.7 20.5-5.1 42.8-8.6 65.1-8.6s44.5 3.4 65.1 8.6c49.7-32.5 70.2-25.7 70.2-25.7 13.7 36 5.1 61.6 1.7 68.5 17.1 17.1 25.7 41.1 25.7 68.5 0 99.3-59.9 119.9-116.4 126.7 8.6 8.6 17.1 24 17.1 47.9v70.2c0 6.8 5.1 15.4 17.1 12C440.1 469.9 512 374 512 261 513.7 120.6 399 5.9 256.8 5.9z"
        fillRule="evenodd"
        clipRule="evenodd"
        fill="#12100e"
      />
    </svg>
  )
}
